'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Mappy = require('../L.Mappy')

module.exports = L.Control.extend({
  options: {
    position: 'topleft'
  },

  onAdd: function () {
    this._container = L.DomUtil.create('div', 'mappy-control-logo')
    var _imgContainer = L.DomUtil.create('div', 'mappy-control-logo-img', this._container)
    var url = Mappy.getImgPath() + 'api-logo-2x.png'
    _imgContainer.style.backgroundImage = 'url("' + url + '")'

    return this._container
  },

  /* Remove the option of removing the logo */
  removeFrom: L.Util.falseFn, // 0.7
  remove: L.Util.falseFn // 0.8
})
