'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Mappy = require('../L.Mappy')
var Control = require('./Control')

module.exports = Control.extend({
  options: {
    geolocationMarker: false,
    locateOptions: {}
  },

  _defaultLocateOptions: {
    setView: true,
    maxZoom: 15,
    timeout: 5 * 1000,
    maximumAge: 30 * 1000,
    enableHighAccuracy: true
  },

  onAdd: function (map) {
    var tooltipOptions = this.options.tooltipOptions || {}
    this._isLocated = true
    this.options.locateOptions = L.extend(this._defaultLocateOptions, this.options.locateOptions)
    this._container = L.DomUtil.create('div', 'mappy-control-geolocation')
    this._geolocButton = this._createButton('<span>?</span>', tooltipOptions.location, 'geolocation', this.activateGeolocation)
    map.on('locationfound', this.onGeolocationSuccess, this)
    map.on('locationerror', this.onGeolocationError, this)
    this.previousCompass = NaN

    return this._container
  },

  activateGeolocation: function () {
    if (!this._isLocated) {
      return
    }
    this._isLocated = false
    this._map.off('moveend zoomend', this.deactivate, this)
    L.DomUtil.removeClass(this._geolocButton, 'geolocation-error')
    L.DomUtil.addClass(this._geolocButton, 'searching')
    this._map.fire('locationsearch')
    this._map.locate(this.options.locateOptions)
  },

  onRemove: function (map) {
    map.off('locationfound', this.onGeolocationSuccess, this)
    window.removeEventListener('deviceorientation', this.headingChangeFn)
  },

  onHeadingChange: function (event) {
    var rawValue = (event.webkitCompassHeading ? event.webkitCompassHeading : (360 - event.alpha))
    var screenOrientationCompensation = (screen.orientation ? screen.orientation.angle : window.orientation)
    var compass = rawValue + screenOrientationCompensation

    if (this.previousCompass !== compass) {
      this.geolocationMarker._icon.firstChild.style.transform = 'rotateZ(' + compass + 'deg)'
      this.previousCompass = compass
    }
  },

  onGeolocationSuccess: function (location) {
    this._isLocated = true
    L.DomUtil.removeClass(this._geolocButton, 'searching')
    this.activateButton(this._geolocButton)

    // Add a little delay to wait before map has moved
    // I can’t use moveend / zoomend event because the map could NOT have moved (if you click button twice for example)...
    setTimeout(function () {
      this._map.once('moveend zoomend', this.deactivate, this)
    }.bind(this), 500)

    if (!this.options.geolocationMarker) {
      return
    }

    if (!this.geolocationMarker) {
      var iconImg = 'marker_geolocation_x2.png'
      var icon = new L.DivIcon({
        className: 'geolocationMarker',
        iconSize: [30, 30],
        iconAnchor: [15, 15],
        html: '<img src="' + Mappy.getImgPath() + iconImg + '" width="30" height="30"/>'
      })

      this.geolocationMarker = L.marker(location.latlng, {
        icon: icon
      }).addTo(this._map)
    } else {
      this.geolocationMarker.setLatLng(location.latlng)
    }

    window.removeEventListener('deviceorientation', this.headingChangeFn)
    this.headingChangeFn = this.onHeadingChange.bind(this)
    window.addEventListener('deviceorientation', this.headingChangeFn)
  },

  onGeolocationError: function (locationEvent) {
    this._isLocated = true
    L.DomUtil.removeClass(this._geolocButton, 'searching')
    L.DomUtil.addClass(this._geolocButton, 'geolocation-error')
    this._map.once('moveend zoomend', L.bind(function () {
      L.DomUtil.removeClass(this._geolocButton, 'geolocation-error')
    }, this))
  },

  deactivate: function () {
    this.deactivateButton(this._geolocButton)
  }
})
