'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Mappy = require('../L.Mappy')

module.exports = L.Control.extend({
  options: {
    position: 'bottomright'
  },

  initialize: function (options) {
    this.options = L.extend({}, this.options, options)
  },

  onAdd: function () {
    this._container = L.DomUtil.create('div', 'mappy-bike-legend')

    var left = L.DomUtil.create('div', 'mappy-bike-legend-col mappy-bike-legend-left', this._container)
    L.DomUtil.create('span', 'mappy-bike-legend-bicyclepath-box', left)

    var right = L.DomUtil.create('div', 'mappy-bike-legend-col mappy-bike-legend-right', this._container)
    var title = L.DomUtil.create('p', 'mappy-bike-legend-title', right)

    title.innerHTML = Mappy.getText('bikelegend.title')

    return this._container
  }
})
