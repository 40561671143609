'use strict'

module.exports = function (func, timeout) {
  var timeoutID
  timeout = timeout || 300
  return function () {
    var scope = this
    var args = arguments
    clearTimeout(timeoutID)
    timeoutID = setTimeout(function () {
      func.apply(scope, Array.prototype.slice.call(args))
    }, timeout)
  }
}
