'use strict'
// override native leaflet stuff
require('./tools/Layers')
require('./tools/Zoom')

// new type of control
require('./tools/TrafficLegend')

// Mappy definitions
var Mappy = require('./L.Mappy')

Mappy.Map = require('./L.Mappy.Map')
Mappy.Services = require('./L.Mappy.Services')
Mappy.Route = require('./L.Mappy.Route')
Mappy.Control = require('./tools/Control')
Mappy.TileLayer = require('./tools/TileLayer') //

Mappy.route = Mappy.Route.route
Mappy.Vehicles = [
  'car',
  'bike',
  'walk'
]

module.exports = Mappy

window.L.Mappy = Mappy
window.messages = require('./tools/Messages')
