'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var Control = require('./Control')

L.Control.Zoom = L.Control.Zoom = module.exports = Control.extend({
  options: {
    zoomSlider: false,
    tooltipOptions: null /* tooltipOptions : { zoomIn: String, zoomOut: String} */
  },

  onAdd: function (map) {
    this._container = this._getContainer('zoom')
    this._initLayout()

    return this._container
  },

  onRemove: function (map) {
    map
      .off('zoomlevelschange', this._updateSize, this)
      .off('zoomend zoomlevelschange', this._updateKnobValue, this)
  },

  _initLayout: function () {
    var additionalClass = (this.options.zoomSlider) ? ' mappy-button-zoom-full' : ''
    var tooltipOptions = this.options.tooltipOptions || {}
    this._buttons.zoomIn = this._createButton('<span>+</span>', tooltipOptions.zoomIn, 'zoom-in' + additionalClass, function (e) {
      this._map.zoomIn(e.shiftKey ? 3 : 1)
    })

    if (this.options.zoomSlider) {
      this._buttons.slider = this._createSlider()
      this._buttons.knob = new Knob(this._buttons.slider.knob, 8, 8)

      this._map.whenReady(this._initKnob, this)
        .whenReady(this._initEvents, this)
        .whenReady(this._updateSize, this)
        .whenReady(this._updateKnobValue, this)
    }

    this._buttons.zoomOut = this._createButton('<span>-</span>', tooltipOptions.zoomOut, 'zoom-out' + additionalClass, function (e) {
      this._map.zoomOut(e.shiftKey ? 3 : 1)
    })
  },

  _createSlider: function () {
    var ui = {}

    ui.bar = L.DomUtil.create('div', 'mappy-slider', this._container)
    ui.wrap = L.DomUtil.create('div', 'mappy-slider-wrap', ui.bar)
    ui.body = L.DomUtil.create('div', 'mappy-slider-body', ui.wrap)
    ui.knob = L.DomUtil.create('div', 'mappy-slider-knob')

    L.DomEvent.disableClickPropagation(ui.bar)
    L.DomEvent.disableClickPropagation(ui.knob)

    return ui
  },

  _initKnob: function () {
    this._buttons.knob.enable()
    this._buttons.slider.body.appendChild(this._buttons.slider.knob)
  },

  _initEvents: function () {
    this._map
      .on('zoomlevelschange', this._updateSize, this)
      .on('zoomend zoomlevelschange', this._updateKnobValue, this)

    L.DomEvent.on(this._buttons.slider.body, 'click', this._onSliderClick, this)

    this._buttons.knob.on('dragend', this._updateMapZoom, this)
  },

  _onSliderClick: function (e) {
    var first = (e.touches && e.touches.length === 1 ? e.touches[0] : e)
    var y = L.DomEvent.getMousePosition(first, this._buttons.slider.body).y

    this._buttons.knob.setPosition(y)
    this._updateMapZoom()
  },

  _zoomLevels: function () {
    var zoomLevels = this._map.getMaxZoom() - this._map.getMinZoom() + 1
    return zoomLevels < Infinity ? zoomLevels : 0
  },

  _toZoomLevel: function (value) {
    return value + this._map.getMinZoom()
  },

  _toValue: function (zoomLevel) {
    return zoomLevel - this._map.getMinZoom()
  },

  _updateSize: function () {
    var steps = this._zoomLevels()

    this._buttons.slider.body.style.height = 8 * steps + 'px'
    this._buttons.knob.setSteps(steps)
  },

  _updateMapZoom: function () {
    this._map.setZoom(this._toZoomLevel(this._buttons.knob.getValue()))
  },

  _updateKnobValue: function () {
    this._buttons.knob.setValue(this._toValue(this._map.getZoom()))
  }

})

var Knob = L.Draggable.extend({
  initialize: function (element, stepHeight, knobHeight) {
    L.Draggable.prototype.initialize.call(this, element, element)
    this._element = element

    this._stepHeight = stepHeight
    this._knobHeight = knobHeight

    this.on('predrag', function () {
      this._newPos.x = 0
      this._newPos.y = this._adjust(this._newPos.y)
    }, this)
  },

  _adjust: function (y) {
    var value = Math.round(this._toValue(y))
    value = Math.max(0, Math.min(this._maxValue, value))
    return this._toY(value)
  },

// y = k*v + m
  _toY: function (value) {
    return this._k * value + this._m
  },

// v = (y - m) / k
  _toValue: function (y) {
    return (y - this._m) / this._k
  },

  setSteps: function (steps) {
    var sliderHeight = steps * this._stepHeight
    this._maxValue = steps - 1

// conversion parameters
// the conversion is just a common linear function.
    this._k = -this._stepHeight
    this._m = sliderHeight - (this._stepHeight + this._knobHeight) / 2
  },

  setPosition: function (y) {
    L.DomUtil.setPosition(this._element, L.point(0, this._adjust(y)))
  },

  setValue: function (v) {
    this.setPosition(this._toY(v))
  },

  getValue: function () {
    return this._toValue(L.DomUtil.getPosition(this._element).y)
  }
})
