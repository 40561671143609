'use strict'

var messages = require('./Messages')
var Mappy = require('../L.Mappy')

function decorateCallbackWithDeprecationHandling (callback) {
  return function (responseOrError) {
    var deprecationStatus = responseOrError && responseOrError.deprecation_status ? responseOrError.deprecation_status : {}

    var isLogsEnabled = Mappy.isLogsEnabled()
    var isDeprecated = deprecationStatus.status !== messages.OK_DEPRECATION_STATUS

    if (isLogsEnabled && isDeprecated) {
      if (console) {
        if (typeof console.warn === 'function') {
          console.warn(deprecationStatus.message || messages.DEFAULT_DEPRECATION_MESSAGE)
        } else if (typeof console.log === 'function') {
          console.log(deprecationStatus.message || messages.DEFAULT_DEPRECATION_MESSAGE)
        }
      }
    }

    return callback(responseOrError, deprecationStatus)
  }
}

module.exports = function (successCallback, failureCallback) {
  return {
    successCallback: decorateCallbackWithDeprecationHandling(successCallback),
    failureCallback: decorateCallbackWithDeprecationHandling(failureCallback)
  }
}
