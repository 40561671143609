'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)
var TileLayer = require('./TileLayer')

module.exports = L.Control.Attribution.extend({
  options: {
    scale: true,
    position: 'bottomleft',
    'prefix': '&copy; <a href="https://blog.mappy.com/entreprise/conditions-dutilisations/copyright/" title="Mappy" target="_blank">Mappy</a> '
  },

  _layers: [],

  onAdd: function (map) {
    map.on('layeradd', function (evt) {
      if (evt.layer instanceof TileLayer) {
        this._layers.push(evt.layer)
        evt.layer.on('attributionsrefresh', this._refreshAttributions, this)
      }
    }, this)
    map.on('layerremove', function (evt) {
      for (var i = this._layers.length - 1; i >= 0; i--) {
        if (this._layers[i] === evt.layer) {
          this._layers.splice(i, 1)
          evt.layer.off('attributionsrefresh', this._refreshAttributions, this)
          this._refreshAttributions()
        }
      }
    }, this)

    var container = L.Control.Attribution.prototype.onAdd.call(this, map)

    if (this.options.scale) {
      L.control.scale({imperial: false, position: this.options.position}).addTo(map)
    }

    return container
  },

  addTo: function (map) {
    this._map = map

    var container = this._container = this.onAdd(map)
    var pos = this.getPosition()
    var corner = map._controlCorners[pos]

    L.DomUtil.addClass(container, 'leaflet-control')

    corner.appendChild(container)

    return this
  },

  /* Remove the option of removing the attributions */
  removeFrom: L.Util.falseFn, // 0.7
  remove: L.Util.falseFn, // 0.8

  clearAttributions: function () {
    this._attributions = {}
    this._update()
  },

  /**
   * add on map attributions of all layers (overlay/viewmode)
   **/
  _refreshAttributions: function () {
    this.clearAttributions()
    var attribs = []
    for (var i = this._layers.length - 1; i >= 0; i--) {
      attribs = attribs.concat(this._layers[i].getAttributions())
    }

    for (var j = 0; j < attribs.length; j++) {
      this.addAttribution(attribs[j])
    }
  }
})
