'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)

function first (a) {
  return a[0]
}

function last (a) {
  return a.slice(-1)[0]
}

function wrap (obj) {
  return {
    start: function (path) {
      return wrap(first(obj[path]))
    },
    end: function (path) {
      return wrap(last(obj[path]))
    },
    get: function () {
      return [obj[1], obj[0]]
    },
    wrap: function (path) {
      return wrap(obj[path])
    }
  }
}

function latLng (section, op) {
  return wrap(section)[op]('features').wrap('geometry')[op]('coordinates').get()
}

function marker (section, type) {
  var src = L.Mappy.getImgPath() + 'roadbook-step-' + type + '.png'
  return L.marker(latLng(section, type), {
    icon: new L.DivIcon({
      className: 'iti-marker mappy-route-' + type,
      html: '<img src="' + src + '" />',
      iconSize: [40, 50],
      iconAnchor: [20, 50]
    })
  })
}

module.exports.route = function (routes) {
  var section = routes[0].sections[0]
  var geoJSON = L.geoJSON(section)
  return L.featureGroup([geoJSON, marker(section, 'start'), marker(section, 'end')])
}
