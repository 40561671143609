'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)

var Attribution = require('./tools/Attribution')
var Logo = require('./tools/Logo')
var Geolocation = require('./tools/Geolocation')
var Legend = require('./tools/Legend')
var Tooltip = require('./tools/Tooltip')
var TileLayer = require('./tools/TileLayer')

var Mappy = require('./L.Mappy')

module.exports = L.Map.extend({

  /**
   * Map layers (overlay/viewmode) - traffic/public_transport
   * .viewmode (standard/photo/hybrid)
   * .overlay (traffic/public_transport)
   */
  _tileLayers: {},

  _tooltip: null,

  disabledActions: [],

  /**
   *
   * @param element
   * @param options
   */
  initialize: function (element, options) {
    options = options || {}

    if (options.clientId) {
      Mappy.setClientId(options.clientId)
    }
    Mappy._checkClientId()

    // Take zoomControl out of Leaflet map init to handle it our way
    var zoomControlOptions = (options.zoomControl !== undefined) ? options.zoomControl : true
    options.zoomControl = false

    // Take attributionControl out of Leaflet map init to handle it our way
    var attributionControlOptions = (options.attributionControl !== undefined) ? options.attributionControl : {}
    options.attributionControl = false

    // Limit y-drag to map size
    options.maxBounds = options.maxBounds || L.latLngBounds(L.latLng(-90, -100000), L.latLng(90, 100000))
    options.worldCopyJump = (options.worldCopyJump !== undefined) ? options.worldCopyJump : true

    // Limit zoom animation to 3 levels
    options.zoomAnimationThreshold = (options.zoomAnimationThreshold !== undefined) ? options.zoomAnimationThreshold : 3

    this.baseLayers = {
      'standard': new TileLayer('standard', 1, options.tileLayerOptions),
      'photo': new TileLayer('photo', 1, options.tileLayerOptions)
    }

    var publicTransportLayerOptions = {
      minZoom: (options.tileLayerOptions && options.tileLayerOptions.minZoom > 6) ? options.tileLayerOptions.minZoom : 6
    }
    this.overlays = {
      'public_transport': new TileLayer('public_transport', 2, L.extend({}, options.tileLayerOptions, publicTransportLayerOptions)),
      'traffic': new TileLayer('traffic', 2, options.tileLayerOptions),
      'hybrid': new TileLayer('hybrid', 2, options.tileLayerOptions),
      'bicycle': new TileLayer('bicycle', 2, options.tileLayerOptions)
    }

    L.Map.prototype.initialize.call(this, element, options)

    this.attributionControl = new Attribution(attributionControlOptions).addTo(this)

    if (options.logoControl !== false) {
      this.logoControl = new Logo(options.logoControl || {}).addTo(this)
    }

    this.setViewmode(options.viewmode)

    if (zoomControlOptions !== false) {
      this.zoomControl = L.control.zoom(zoomControlOptions || {}).addTo(this)
    }

    if (options.layersControl === undefined || options.layersControl) {
      this.layersControl = L.control.layers(this.baseLayers, this.overlays, options.layersControl || {}).addTo(this)
    }

    if (options.geolocationControl && ('geolocation' in navigator)) {
      this.geolocationControl = new Geolocation(options.geolocationControl).addTo(this)
    }

    if (options.legendControl) {
      this.legendControl = new Legend(options.legendControl).addTo(this)
    }

    if (options.tooltip !== false) {
      this.manageTooltip()
    }
  },

  manageTooltip: function () {
    this._tooltip = new Tooltip({
      map: this,
      showDelay: 0,
      hideDelay: 0
    })

    // bind events
    this.on('mousemove', this._handleMousemove)

    // hide background items' tooltip on drag/zoom
    this.on('move', this._tooltip.hide, this._tooltip)
    this.on('zoomstart', this._tooltip.hide, this._tooltip)
  },

  /**
   * Sets the background map layer (viewmode). Available viewmodes : standard, photo & hybrid.
   *
   * @param {string} name
   */
  setViewmode: function (name) {
    if (name === 'hybrid') {
      this.setOverlay(name)
    }

    name = name === 'hybrid' ? 'photo' : (name || 'standard')

    this._setTileLayer(this.baseLayers, name)
    if (this.baseLayers[name]) {
      this.fire('viewmode-' + name, this.baseLayers[name])
    }
  },

  /**
   * Sets the overlay map layer. Available overlays : traffic & public_transport.
   * If nothing is specified, remove the current overlay.
   *
   * @param name
   */
  setOverlay: function (name) {
    this._setTileLayer(this.overlays, name)
    this.fire('overlay-' + (name || 'disabled'), (this.overlays[name] ? this.overlays[name] : null))
  },

  /**
   * Sets the overlay map layer. Available overlays : traffic & public_transport.
   * If nothing is specified, remove the current overlay.
   *
   * @param name
   */
  removeOverlay: function (name) {
    this.removeLayer(this.overlays[name])
    this.fire('overlay-disabled', (this.overlays[name] ? this.overlays[name] : null))
  },

  /**
   * Disable user interactions
   */
  disableInteractions: function () {
    var actions = ['dragging', 'touchZoom', 'scrollWheelZoom', 'doubleClickZoom', 'boxZoom', 'keyboard']
    this.disabledActions = []

    for (var i = 0; i < actions.length; i++) {
      if (this[actions[i]] && this[actions[i]].enabled()) {
        this.disabledActions.push(actions[i])
        this[actions[i]].disable()
      }
    }
  },

  /**
   * Enable previously disabled interactions
   */
  enableInteractions: function () {
    for (var i = 0; i < this.disabledActions.length; i++) {
      this[this.disabledActions[i]].enable()
    }
  },

  /**
   * Returns the active Tilelayer of specified type
   *
   * @param type
   */
  getTilelayer: function (type) {
    var layers = type === 'overlay' ? this.overlays : this.baseLayers
    for (var layerName in layers) {
      if (layers.hasOwnProperty(layerName) && this.hasLayer(layers[layerName])) {
        return layers[layerName]
      }
    }
    return null
  },

  /**
   * Sets a map layer (overlay/viewmode) on map
   *
   * @param layers     type of layer to create (viewmode, overlay)
   * @param name      name of the viewmode
   **/
  _setTileLayer: function (layers, name) {
    if (name === 'hybrid') {
      this.addLayer(layers[name])
    } else {
      // remove all layers except for hybrid (but remove hybrid if name is undefined)
      for (var layerName in layers) {
        if (layers.hasOwnProperty(layerName) && this.hasLayer(layers[layerName]) && (layerName !== 'hybrid' || !name)) {
          this.removeLayer(layers[layerName])
        }
      }
      if (layers[name]) {
        this.addLayer(layers[name])
      }
    }
    return this
  },

  /**
   * handle actions depending on cursor position (bind on mousemove event)
   *
   * @param event     "mousemove" leaflet event
   **/
  _handleMousemove: function (event) {
    var items = this.getTilelayer()
    if (!items) {
      return
    }
    items = items.layerItems
    var overlay = this.getTilelayer('overlay')
    if (overlay) {
      items = items.concat(overlay.layerItems)
    }

    var i = 0
    var found = false

    while (i < items.length && !found) {
      var box = items[i].box
      if (box.minx < event.latlng.lng && event.latlng.lng < box.maxx &&
        box.miny < event.latlng.lat && event.latlng.lat < box.maxy) {
        if (!this._tooltip.isVisible()) {
          this._showItemTooltip(items[i])
        }
        found = true
      }
      i++
    }

    if (!found) {
      this._tooltip.hide()
    }
  },

  _showItemTooltip: function (item) {
    var baseUrl = Mappy._getScheme() + '://logotc.' + Mappy._getDomain() + '/pictos/web/desktop/'

    var transportLabels = {
      'M': 'metro',
      'S': 'rer',
      'T': 'train',
      'TY': 'tram'
    }

    var lines = []
    var itemLine = item.properties.description.line
    itemLine = itemLine instanceof Array ? itemLine : [itemLine] // Thx Lbx...
    for (var j = 0; j < itemLine.length; j++) {
      if (!lines[itemLine[j].type]) {
        lines[itemLine[j].type] = []
      }
      lines[itemLine[j].type].push(itemLine[j].num)
    }

    var description = ''

    for (var type in lines) {
      if (lines.hasOwnProperty(type)) {
        var lineType = (transportLabels[type] || type)
        var icons = ['<img src="' + baseUrl + 'modes/' + lineType + '.png" />']

        for (var line in lines[type]) {
          if (lines[type].hasOwnProperty(line)) {
            var lineName = (lineType === 'tram') ? 't' + lines[type][line].toLowerCase() : lines[type][line].toLowerCase()
            icons.push('<img src="' + baseUrl + 'lines/stif_' + lineType + '_' + lineName + '.png" />')
          }
        }

        description += '</p><p>' + icons.join('')
      }
    }

    var popupPosition = this.latLngToContainerPoint(L.latLng((item.box.maxy + item.box.miny) / 2, (item.box.maxx + item.box.minx) / 2))
    this._tooltip.show(popupPosition, '<div><p><span>' + item.properties.description.label + '</span>' + description + '</p></div>')
  },

  addLegendControl: function (options) {
    options = options || this.options.legendControl || {}

    if (this.legendControl) return

    this.legendControl = new Legend(options.legendControl).addTo(this)
  },

  removeLegendControl: function () {
    if (this.legendControl) {
      this.legendControl.remove()
      this.legendControl = null
    }
  }

})

L.Handler.MarkerDrag.prototype._onDrag = function () {
  var marker = this._marker
  var shadow = marker._shadow
  var iconPos = L.DomUtil.getPosition(marker._icon)
  var topLeft = marker._map.containerPointToLayerPoint([0, 0])
  var bottomRight = marker._map.containerPointToLayerPoint(marker._map.getSize())

  // Limit marker position to map bounds
  var newPos = new L.Point(
    Math.max(topLeft.x + 5, Math.min(iconPos.x, bottomRight.x - 5)),
    Math.min(bottomRight.y - 5, Math.max(iconPos.y, topLeft.y + 15))
  )
  L.DomUtil.setPosition(marker._icon, newPos)

  var latlng = marker._map.layerPointToLatLng(newPos)

  // update shadow position
  if (shadow) {
    L.DomUtil.setPosition(shadow, newPos)
  }

  marker._latlng = latlng

  marker
    .fire('move', {latlng: latlng})
    .fire('drag')
}
