'use strict'

var L = (typeof window !== "undefined" ? window['L'] : typeof global !== "undefined" ? global['L'] : null)

var Mappy = require('../L.Mappy')
var Control = require('./Control')

L.Control.TrafficLegend = module.exports = Control.extend({
  options: {
    position: 'bottomright'
  },

  _initLayout: function () {
    this._container = L.DomUtil.create('div', 'mappy-traffic-legend clearfix')

    L.DomUtil.create('p', 'mappy-traffic-legend-title', this._container)

    var table = L.DomUtil.create('table', '', this._container)
    var tr = L.DomUtil.create('tr', '', table)
    L.DomUtil.create('td', 'yellow', tr)
    L.DomUtil.create('td', 'orange', tr)
    L.DomUtil.create('td', 'red', tr)
    L.DomUtil.create('td', 'darkred', tr)
    L.DomUtil.create('td', 'black', tr)

    L.DomUtil.create('p', 'left', this._container).innerHTML = Mappy.getText('trafficLegend.slow')

    L.DomUtil.create('p', 'right', this._container).innerHTML = Mappy.getText('trafficLegend.blocked')

    return this._container
  },

  show: function (map) {
    if (this._map) {
      this.refresh()

      if (this._container) {
        L.DomUtil.addClass(this._container.parentElement, 'leaflet-control-with-traffic')
      }
      return
    }

    this.addTo(map)
    if (this._container) {
      L.DomUtil.addClass(this._container.parentElement, 'leaflet-control-with-traffic')
    }
  },

  hide: function () {
    if (this._container) {
      L.DomUtil.removeClass(this._container.parentElement, 'leaflet-control-with-traffic')
    }

    if (this._map) {
      this.removeFrom(this._map)
      this._map = null
    }
  },

  onAdd: function () {
    if (!this._container) {
      this._initLayout()
    }
    this.refresh()
    this.interval = window.setInterval(L.bind(this.refresh, this), 120000)

    return this._container
  },

  onRemove: function () {
    window.clearInterval(this.interval)
  },

  refresh: function () {
    var now = new Date()
    this._container.querySelector('p.mappy-traffic-legend-title').innerHTML = L.Util.template(Mappy.getText('trafficLegend.trafficAt'), {
      hours: now.getHours(),
      minutes: (now.getMinutes() < 10 ? '0' : '') + now.getMinutes()
    })
  }
})

L.control.TrafficLegend = function (options) {
  return new L.Control.TrafficLegend(options)
}
